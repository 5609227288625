var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "span" },
    [
      _c("van-nav-bar", {
        attrs: { title: _vm.title, placeholder: "", fixed: "", border: false }
      }),
      _c(
        "div",
        { staticClass: "body" },
        [
          _c("van-overlay", { attrs: { show: _vm.show !== 0 } }),
          _c(
            "div",
            [
              _c(
                "van-uploader",
                {
                  attrs: {
                    multiple: "",
                    "max-count": 10,
                    "after-read": _vm.afterRead,
                    "before-read": _vm.beforeRead
                  },
                  model: {
                    value: _vm.fileList,
                    callback: function($$v) {
                      _vm.fileList = $$v
                    },
                    expression: "fileList"
                  }
                },
                [
                  _c(
                    "el-button",
                    { staticClass: "button", attrs: { type: "primary" } },
                    [_vm._v("相册/拍照")]
                  )
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "button",
                  attrs: { type: "success", loading: _vm.show !== 0 },
                  on: { click: _vm.upload }
                },
                [_vm._v("开始上传 ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }